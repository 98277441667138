<template>
  <div>
    <vx-button
      :variant="variant"
      class="mr-1 mt-1"
      :to="players"
    >
      Players
    </vx-button>
    <vx-button
      :variant="variant"
      class="mr-1 mt-1"
      :to="credentials"
    >
      Credentials
    </vx-button>
    <vx-button
      :variant="variant"
      class="mt-1"
      :to="activations"
    >
      Activations
    </vx-button>
  </div>
</template>

<script>
import { VxButton } from '@/components/buttons'
import { buildRoute, getRouteIdParam, path } from '@/router'

export default {
  name: 'AntifraudButtons',
  components: { VxButton },
  setup () {
    const variant = 'primary'

    const players = buildRoute(path.antifraudRecords, { query: { player_id: getRouteIdParam() } })
    const credentials = buildRoute(path.antifraudCredentials, { query: { player_id: getRouteIdParam() } })
    const activations = buildRoute(path.antifraudActivations, { query: { player_id: getRouteIdParam() } })

    return {
      variant,

      players,
      credentials,
      activations
    }
  }
}
</script>
