var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-form',{attrs:{"resource":_vm.formResource},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [(_vm.isCreate)?_c('vx-select-resource',{attrs:{"rules":_vm.rules.player_id,"resource":_vm.playerResource,"reduce":function (ref) {
	var id = ref.id;

	return id;
},"options-label":"name","label":"Player","name":"player_id"},model:{value:(_vm.formValue.player_id),callback:function ($$v) {_vm.$set(_vm.formValue, "player_id", $$v)},expression:"formValue.player_id"}}):_vm._e(),(_vm.isCreateAndResourceExist)?_c('vx-select-resource',{attrs:{"rules":_vm.rules.amount_currency,"resource":_vm.currencyResource,"reduce":function (ref) {
	var currency_code = ref.currency_code;

	return currency_code;
},"disabled":!_vm.formValue.player_id,"suppress-options-pushing":"","options-label":"currency_code","label":"Currency","name":"amount_currency"},model:{value:(_vm.formValue.amount_currency),callback:function ($$v) {_vm.$set(_vm.formValue, "amount_currency", $$v)},expression:"formValue.amount_currency"}}):_vm._e(),_c('vx-input',{attrs:{"rules":_vm.rules.amount,"append-text":_vm.appendText,"type":"number","name":"amount","step":"0.01","label":"Amount"},model:{value:(_vm.formValue.amount),callback:function ($$v) {_vm.$set(_vm.formValue, "amount", $$v)},expression:"formValue.amount"}}),_c('vx-input',{attrs:{"rules":_vm.rules.reason,"name":"reason","label":"Reason"},model:{value:(_vm.formValue.reason),callback:function ($$v) {_vm.$set(_vm.formValue, "reason", $$v)},expression:"formValue.reason"}}),_c('vx-button',{attrs:{"loading":loading,"variant":"primary","type":"submit"}},[_vm._v(" Save ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }