<template>
  <vx-table
    :columns="columns"
    :resource="resource"
    :data-mapper="dataMapper"
    stacked
    hide-pagination
    suppress-route-handling
  >
    <template #title>
      Last activation
    </template>
  </vx-table>
</template>

<script>
import VxTable from '@/components/table/vx-table/VxTable'
import { antifraud, passDataToResource } from '@/services/resources'
import { getRouteIdParam } from '@/router'
import { toArray } from '@/services/utils'
import { VxCellAntifraudPoints } from '@/components/table'

export default {
  name: 'AntifraudLastActivation',
  components: {
    VxTable,
    VxCellAntifraudPoints
  },
  setup () {
    const resource = passDataToResource(antifraud.getAllActivations, {
      requestParams: {
        params: {
          filter: { player_id: getRouteIdParam() }
        }
      }
    })

    const dataMapper = (values) => values.at(-1) ? toArray(values.at(-1)) : []

    const columns = [
      {
        key: 'point_log',
        formatter: (value) => toArray(value.at(-1)),
        component: VxCellAntifraudPoints
      }
    ]

    return {
      resource,
      columns,
      dataMapper,
      toArray
    }
  }
}
</script>
