<template>
  <div>
    <vx-button
      :variant="variant"
      class="mr-1 mt-1"
      :to="drops"
    >
      Drops
    </vx-button>
    <vx-button
      :variant="variant"
      class="mr-1 mt-1"
      :to="upgrades"
    >
      Upgrades
    </vx-button>
    <vx-button
      :variant="variant"
      class="mt-1"
      :to="exchanges"
    >
      Exchanges
    </vx-button>
  </div>
</template>

<script>
import { VxButton } from '@/components/buttons'
import { buildRoute, getRouteIdParam, path } from '@/router'

export default {
  name: 'DropsButtons',
  components: { VxButton },
  setup () {
    const variant = 'primary'

    const drops = buildRoute(path.drops, { query: { player_id: getRouteIdParam() } })
    const upgrades = buildRoute(path.upgrades, { query: { player_id: getRouteIdParam() } })
    const exchanges = buildRoute(path.exchanges, { query: { player_id: getRouteIdParam() } })

    return {
      variant,

      drops,
      upgrades,
      exchanges
    }
  }
}
</script>
