import { ref } from '@vue/composition-api'

export const useModalEditor = () => {
  const modalIsOpen = ref(false)
  const entity = ref(null)

  const openModal = (selectedEntity = null) => {
    entity.value = selectedEntity
    modalIsOpen.value = true
  }

  const closeModal = () => {
    modalIsOpen.value = false
    entity.value = null
  }

  return {
    modalIsOpen,
    entity,
    openModal,
    closeModal
  }
}
