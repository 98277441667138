<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-select-resource
      v-if="isCreate"
      v-model="formValue.player_id"
      :rules="rules.player_id"
      :resource="playerResource"
      :reduce="({ id }) => id"
      options-label="name"
      label="Player"
      name="player_id"
    />

    <vx-select-resource
      v-if="isCreateAndResourceExist"
      v-model="formValue.amount_currency"
      :rules="rules.amount_currency"
      :resource="currencyResource"
      :reduce="({ currency_code }) => currency_code"
      :disabled="!formValue.player_id"
      suppress-options-pushing
      options-label="currency_code"
      label="Currency"
      name="amount_currency"
    />

    <vx-input
      v-model="formValue.amount"
      :rules="rules.amount"
      :append-text="appendText"
      type="number"
      name="amount"
      step="0.01"
      label="Amount"
    />

    <vx-input
      v-model="formValue.reason"
      :rules="rules.reason"
      name="reason"
      label="Reason"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { reactive, ref, watch } from '@vue/composition-api'
import { setValuesToForm } from '@/services/form'
import { VxForm, VxInput, VxSelectResource } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { balanceChanges, passDataToResource, players } from '@/services/resources'
import { brcCurrency, formatToCents, formatToDecimal, getCurrencySymbol, mainCurrencyCode } from '@/services/utils'

export default {
  name: 'BalanceChangeRequestEditor',
  components: {
    VxForm,
    VxInput,
    VxSelectResource,
    VxButton
  },
  props: {
    entity: {
      type: Object,
      default: null
    },
    playerId: {
      type: Number,
      default: null
    },
    currencyRequest: {
      type: String,
      default: mainCurrencyCode
    }
  },
  setup ({ entity, currencyRequest, playerId }, { emit }) {
    const isEdit = !!entity

    const formValue = reactive({
      player_id: null,
      amount_currency: null,

      amount: 0,
      reason: ''
    })

    const playerResource = players.getAll
    const currencyResource = ref(null)

    const currency = isEdit ? entity?.amount?.currency : currencyRequest
    const currencySymbol = getCurrencySymbol(currency)
    const isCreate = !playerId && !entity
    const appendText = isCreate ? null : currencySymbol
    const isCreateAndResourceExist = isCreate && currencyResource

    const backToFrontMapper = ({ amount: { amount }, reason }) => ({
      reason,
      amount: formatToDecimal(amount)
    })
    const frontToBackMapper = ({ amount, reason }) => {
      const payload = {
        reason,
        amount: formatToCents(amount),
        amount_currency: currency
      }

      if (!playerId && !entity) {
        return {
          ...formValue,
          amount: formatToCents(amount)
        }
      }

      return isEdit ? payload : { ...payload, player_id: playerId }
    }

    const formResource = isEdit
      ? passDataToResource(balanceChanges.updateRequest, {
        frontToBackMapper,
        requestParams: { urlParams: { id: entity.id } }
      })
      : passDataToResource(balanceChanges.createRequest, {
        frontToBackMapper
      })

    if (isEdit) setValuesToForm(backToFrontMapper(entity), formValue)

    const rules = {
      player_id: {
        required: true
      },
      amount_currency: {
        required: true
      },
      amount: {
        required: true,
        max_value: 1000,
        is_not: 0,
        decimal: currency === brcCurrency ? 0 : 2
      },
      reason: {
        required: true,
        min: 2,
        max: 256
      }
    }

    const handleSubmit = ([, res]) => {
      if (res) {
        emit('submit', res)
      }
    }

    watch(() => formValue.player_id, () => {
      formValue.amount_currency = null

      if (formValue.player_id) {
        currencyResource.value = passDataToResource(players.accounts, {
          requestParams: {
            params: {
              include: 'currency',
              filter: { player_id: formValue.player_id }
            }
          }
        })
      }
    })

    return {
      formValue,
      formResource,
      rules,

      isCreate,
      isCreateAndResourceExist,

      playerResource,
      currencyResource,

      handleSubmit,
      currencySymbol,
      appendText
    }
  }
}
</script>
