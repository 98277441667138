<template>
  <div class="d-flex flex-wrap">
    <vx-button
      :variant="variant"
      class="mr-1 mt-1"
      :to="balanceChanges"
    >
      Balance changes
    </vx-button>
    <vx-button
      :variant="variant"
      class="mr-1 mt-1"
      :to="payments"
    >
      Payments
    </vx-button>
    <vx-button
      :variant="variant"
      class="mr-1 mt-1"
      :to="balanceChangeRequests"
    >
      Balance change requests
    </vx-button>
    <vx-button
      :variant="variant"
      class="mr-1 mt-1"
      :to="promocodes"
    >
      Promocodes
    </vx-button>
    <vx-button
      :variant="variant"
      class="mt-1"
      :to="activeBonuses"
    >
      Active bonuses
    </vx-button>
  </div>
</template>

<script>
import { VxButton } from '@/components/buttons'
import { buildRoute, getRouteIdParam, path } from '@/router'

export default {
  name: 'FinancesButtons',
  components: { VxButton },
  setup () {
    const variant = 'primary'

    const balanceChanges = buildRoute(path.balanceChanges,
      { query: { player_id: getRouteIdParam() } }
    )
    const payments = buildRoute(path.payments,
      { query: { player_id: getRouteIdParam() } }
    )
    const balanceChangeRequests = buildRoute(path.balanceChangeRequests,
      { query: { player_id: getRouteIdParam() } }
    )
    const promocodes = buildRoute(path.promocodes,
      { query: { id: getRouteIdParam() } }
    )
    const activeBonuses = buildRoute(path.bonusUsages,
      { query: { player_id: getRouteIdParam() } }
    )

    return {
      variant,

      balanceChanges,
      payments,
      balanceChangeRequests,
      promocodes,
      activeBonuses
    }
  }
}
</script>
