<template>
  <vx-card
    :loading="getLimitLoading"
    no-body
  >
    <template #title>
      Withdrawal Limit
    </template>

    <b-card-body class="border-top pt-1">
      <vx-form
        v-slot="{ can, loading }"
        :resource="formResource"
        @submit="handleSubmit"
      >
        <vx-checkbox
          v-model="formValue.is_active"
          name="is_active"
          label="Active"
        />

        <vx-input
          v-model="formValue.price_limit"
          :rules="rules.price_limit"
          name="price_limit"
          label="Limit"
          type="number"
          step="0.01"
        />

        <b-row class="mb-1">
          <b-col cols="4">
            <span>Already withdrawn</span>
          </b-col>
          <b-col cols="8">
            <span>$ {{ alreadyWithdrawn }}</span>
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col cols="4">
            <span>Refresh date</span>
          </b-col>
          <b-col cols="8">
            <span>{{ refreshDate }}</span>
          </b-col>
        </b-row>

        <vx-date-picker
          v-if="hasWithdrawalLimit"
          v-model="formValue.customApplyFrom"
          :clearable="false"
          name="apply_from"
          label="Apply from"
          type="date"
        />

        <vx-button
          :loading="loading"
          :can="can"
          variant="primary"
          type="submit"
        >
          {{ buttonTitle }}
        </vx-button>
      </vx-form>
    </b-card-body>
  </vx-card>
</template>

<script>
import { onBeforeMount, reactive, ref, computed } from '@vue/composition-api'
import { setValuesToForm } from '@/services/form'
import { VxForm, VxCheckbox, VxInput, VxDatePicker } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { passDataToResource, players, useResource } from '@/services/resources'
import VxCard from '@/components/VxCard'
import { getRouteIdParam } from '@/router'
import { dateFormat, dateFormats, formatToCents, formatToDecimal, formatToISODate } from '@/services/utils'
import { isEmpty } from 'lodash'
import { BCardBody, BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'ProfileWithdrawalLimit',
  components: {
    VxCard,
    VxDatePicker,
    VxForm,
    VxCheckbox,
    VxInput,
    VxButton,
    BRow,
    BCol,
    BCardBody
  },
  setup () {
    const playerId = getRouteIdParam()
    const {
      can: canGetLimit,
      callRequest: getLimitRequest,
      loading: getLimitLoading
    } = useResource(players.withdrawalLimit)

    const hasWithdrawalLimit = ref(false)
    const buttonTitle = computed(() => hasWithdrawalLimit.value ? 'Update' : 'Create')

    const formValue = reactive({
      is_active: false,
      price_limit: 0,
      total_drop_price: 0,
      apply_from: null,
      customApplyFrom: null
    })

    const rules = {
      price_limit: {
        required: true,
        decimal: 2,
        min_value: 0,
        max_value: 15000
      }
    }

    const refreshDate = computed(() => {
      return formValue.apply_from
        ? dateFormat(formValue.apply_from, dateFormats.date)
        : 'No data available'
    })

    const alreadyWithdrawn = computed(() => {
      return formatToDecimal(formValue.total_drop_price)
    })

    const backToFrontMapper = (value) => {
      return {
        ...value,
        price_limit: formatToDecimal(value.price_limit),
        customApplyFrom: null
      }
    }

    const frontToBackMapper = ({ price_limit, is_active }) => {
      const result = {
        is_active,
        price_limit: formatToCents(price_limit)
      }

      if (hasWithdrawalLimit.value) {
        const refreshRules = formValue.customApplyFrom
          ? { apply_from: dateFormat(formatToISODate(formValue.customApplyFrom), 'yyyy-MM-dd') }
          : null

        return { ...result, ...refreshRules }
      }

      return result
    }

    const formResource = computed(() => {
      return hasWithdrawalLimit.value
        ? passDataToResource(players.updateWithdrawalLimit, {
          frontToBackMapper,
          requestParams: { urlParams: { id: playerId } }
        })
        : passDataToResource(players.createWithdrawalLimit, {
          frontToBackMapper,
          requestParams: { urlParams: { id: playerId } }
        })
    })

    const getPlayerWithdrawal = async () => {
      if (!canGetLimit) return

      const [err, res] = await getLimitRequest({
        urlParams: { id: playerId }
      })
      if (err) return

      setValuesToForm(backToFrontMapper(res.data), formValue)
      hasWithdrawalLimit.value = !isEmpty(res.data)
    }

    const handleSubmit = ([err, res]) => {
      if (err) return

      setValuesToForm(backToFrontMapper(res.data), formValue)
      hasWithdrawalLimit.value = !isEmpty(res.data)
    }

    onBeforeMount(async () => {
      await getPlayerWithdrawal()
    })

    return {
      hasWithdrawalLimit,
      buttonTitle,

      getLimitLoading,

      formResource,
      formValue,
      rules,
      handleSubmit,

      refreshDate,
      alreadyWithdrawn
    }
  }
}
</script>
