<template>
  <div v-if="items">
    <vx-table-simple
      :items="items"
      :columns="columns"
      :loading="loading"
    >
      <template #title>
        Pending changes requests
      </template>
    </vx-table-simple>
    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <balance-change-request-editor
        :entity="entity"
        @submit="handleSubmit"
      />
    </b-modal>
  </div>
</template>

<script>
import { VxCellButtons, VxCellDate, VxCellLink, VxCellMoney, VxTableSimple } from '@/components/table'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { useModalEditor } from '@/services/modal'
import { BModal } from 'bootstrap-vue'
import BalanceChangeRequestEditor from '@/views/balance-changes/BalanceChangeRequestEditor'
import { useResource, passDataToResource, balanceChanges, paymentStatuses, admins } from '@/services/resources'
import { useToast } from '@/plugins/toastification'
import { buildRoute, getRouteIdParam, path } from '@/router'

export default {
  name: 'FinancesPendingRequests',
  components: {
    VxTableSimple,
    VxCellMoney,
    VxCellDate,
    VxCellLink,
    BalanceChangeRequestEditor,
    BModal
  },
  props: {
    financesPendingRequests: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const toast = useToast()
    const loading = ref(false)
    const items = ref([])
    const adminsList = ref([])

    const { can: canUpdateChangeRequest } = useResource(balanceChanges.updateRequest)

    const { modalIsOpen, entity, openModal, closeModal } = useModalEditor()

    const modalTitle = 'Edit request'

    const handleSubmit = async () => {
      closeModal()
      loading.value = true
      await getBalanceChangesRequest()
      loading.value = false
    }

    const getBalanceChangesRequest = async () => {
      const resource = passDataToResource(balanceChanges.getAllRequests, {
        requestParams: {
          params: {
            filter: {
              player_id: getRouteIdParam(),
              status: paymentStatuses.pending
            }
          }
        }
      })

      const { can, callRequest } = useResource(resource)
      if (!can) return

      const [err, res] = await callRequest()
      if (err) toast.error(err)

      items.value = res.data
    }

    const getAdmins = async () => {
      const { can, callRequest } = useResource(admins.getAll)
      if (!can) return

      const [err, res] = await callRequest({ params: null })
      if (err) return

      adminsList.value = res.data
    }

    const requestsButtons = (value) => {
      const editRequestButton = {
        ...buttons.edit,
        label: 'Edit request',
        can: canUpdateChangeRequest,
        onClick: () => {
          openModal(value)
        }
      }

      return [editRequestButton]
    }

    onBeforeMount(() => {
      loading.value = true
      Promise.all([getBalanceChangesRequest(), getAdmins()]).then(() => {
        loading.value = false
      })
    })

    watch(() => props.financesPendingRequests, async () => {
      await getBalanceChangesRequest()
    })

    const columns = [
      {
        key: 'amount',
        component: VxCellMoney
      },
      {
        key: 'reason'
      },
      {
        key: 'creator_id',
        label: 'creator',
        component: VxCellLink,
        tdAttr: (creatorId) => {
          const [creator] = adminsList.value.map(({ id, first_name, last_name }) => {
            if (id === creatorId) {
              return `${first_name} ${last_name}`
            }
          }).filter(i => !!i)

          return {
            label: creator,
            href: buildRoute(path.admin, { params: { id: creatorId } })
          }
        }
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: requestsButtons(item)
        })
      }
    ]

    return {
      loading,
      items,
      columns,

      entity,
      handleSubmit,

      modalTitle,
      modalIsOpen,
      openModal,
      closeModal
    }
  }
}
</script>
