import ProfileGeneralInfo from '@/views/players/components/profile/ProfileGeneralInfo'
import ProfileWithdrawalLimit from '@/views/players/components/profile/ProfileWithdrawalLimit'
import ProfileSteamData from '@/views/players/components/profile/ProfileSteamData'
import ProfileProvablyFair from '@/views/players/components/profile/ProfileProvablyFair'
import ProfileTags from '@/views/players/components/profile/ProfileTags'

import FinancesActivePaymentAccount from '@/views/players/components/finances/FinancesActivePaymentAccount'
import FinancesAccountBonuses from '@/views/players/components/finances/FinancesAccountBonuses'
import FinancesPaymentAccountsInfo from '@/views/players/components/finances/FinancesPaymentAccountsInfo'
import FinancesPendingRequests from '@/views/players/components/finances/FinancesPendingRequests'
import FinanceButtons from '@/views/players/components/finances/FinancesButtons'

import DropsMainInfo from '@/views/players/components/drops/DropsMainInfo'
import DropsAdditionalInfo from '@/views/players/components/drops/DropsAdditionalInfo'
import DropsButtons from '@/views/players/components/drops/DropsButtons'

import AntifraudActivePoints from '@/views/players/components/antifraud/AntifraudActivePoints'
import AntifraudLastActivation from '@/views/players/components/antifraud/AntifraudLastActivation'
import AntifraudButtons from '@/views/players/components/antifraud/AntifraudButtons'

export const tabsSchema = [
  {
    name: 'Profile',
    children: [
      {
        propName: 'profileGeneral',
        component: ProfileGeneralInfo
      },
      {
        propName: 'profileSteam',
        component: ProfileSteamData
      },
      {
        propName: 'profileProvablyFair',
        component: ProfileProvablyFair
      },
      {
        propName: 'profileTags',
        component: ProfileTags
      },
      {
        component: ProfileWithdrawalLimit
      }
    ]
  },
  {
    name: 'Finances',
    children: [
      {
        propName: 'financesActivePaymentAccount',
        component: FinancesActivePaymentAccount
      },
      {
        propName: 'financesAccountBonuses',
        component: FinancesAccountBonuses
      },
      {
        component: FinancesPaymentAccountsInfo
      },
      {
        propName: 'financesPendingRequests',
        component: FinancesPendingRequests
      },
      {
        fullSize: true,
        component: FinanceButtons
      }
    ]
  },
  {
    name: 'Drops',
    children: [
      {
        propName: 'dropsMainInfo',
        component: DropsMainInfo
      },
      {
        propName: 'dropsAdditionalInfo',
        component: DropsAdditionalInfo
      },
      {
        fullSize: true,
        component: DropsButtons
      }
    ]
  },
  {
    name: 'Antifraud',
    children: [
      {
        propName: 'antifraudActivePoints',
        component: AntifraudActivePoints
      },
      {
        component: AntifraudLastActivation
      },
      {
        fullSize: true,
        component: AntifraudButtons
      }
    ]
  }
]
