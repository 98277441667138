import { reactive } from '@vue/composition-api'

export const usePlayerAdapter = () => {
  const playerModel = reactive({
    profileMain: {},
    profileGeneral: {},
    profileSteam: {},
    profileProvablyFair: {},
    profileTags: {},

    financesActivePaymentAccount: {},
    financesAccountBonuses: {},
    financesPendingRequests: {},

    dropsMainInfo: {},
    dropsAdditionalInfo: {},

    antifraudActivePoints: {}
  })

  const backToFrontMapper = async (data) => {
    const {
      id,
      name,
      email,
      avatar,
      tracking,
      profile,
      xp,
      created_at,
      updated_at,
      payment_account,
      brc_account,
      total_payment_sum,
      total_withdrawal_sum,
      total_drops_withdrawn_sum,
      active_drop_sum,
      withdrawing_drop_sum,
      upgradable_drop_sum,
      upgrade_bet_sum,
      upgrade_won_sum,
      steam_id,
      steam_url,
      trade_url,
      steam_profile,
      latest_client_seed,
      latest_server_seed,
      tags,
      active_bonus_count,
      disabled_bonus_count,
      expired_bonus_count,
      total_bonus_count,
      used_bonus_count,
      used_promocode_count,
      active_antifraud_activation,
      active_drop_count,
      drop_count,
      withdrawal_drop_limit,
      chest_drop_count,
      exchange_drop_count,
      player_totals,
      sellable_drop_sum,
      upgrade_drop_count,
      withdrawing_drop_count,
      usual_chest_opening_count,
      usual_chest_spent_sum,
      usual_chest_won_item_sum,
      exchange_count,
      player_welcome_offer
    } = data || {}

    playerModel.profileMain = { id, name, email, avatar }
    playerModel.profileGeneral = { xp, name, created_at, updated_at, id, tracking, profile, player_welcome_offer }
    playerModel.profileSteam = { steam_id, steam_profile, steam_url, trade_url }
    playerModel.profileProvablyFair = { latest_client_seed, latest_server_seed }
    playerModel.profileTags = { tags }

    playerModel.financesActivePaymentAccount = {
      payment_account,
      brc_account,
      total_payment_sum,
      total_drops_withdrawn_sum
    }

    playerModel.financesAccountBonuses = {
      active_bonus_count,
      disabled_bonus_count,
      expired_bonus_count,
      total_bonus_count,
      used_bonus_count,
      used_promocode_count
    }

    playerModel.financesPendingRequests = { id }

    playerModel.dropsMainInfo = {
      active_drop_count,
      drop_count,
      withdrawal_drop_limit,
      active_drop_sum,
      chest_drop_count,
      exchange_drop_count,
      player_totals,
      sellable_drop_sum,
      upgradable_drop_sum,
      upgrade_drop_count,
      withdrawing_drop_count,
      withdrawing_drop_sum,
      total_withdrawal_sum
    }

    playerModel.dropsAdditionalInfo = {
      usual_chest_opening_count,
      usual_chest_spent_sum,
      usual_chest_won_item_sum,
      upgrade_bet_sum,
      upgrade_won_sum,
      exchange_count
    }

    playerModel.antifraudActivePoints = { active_antifraud_activation }
  }

  return {
    playerModel,
    backToFrontMapper
  }
}
