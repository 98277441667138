<template>
  <b-row>
    <b-col cols="12" lg="2">
      <main-info :data="playerModel.profileMain" />
    </b-col>
    <b-col cols="12" lg="10">
      <vx-card>
        <b-tabs v-model="tabIndex" fill>
          <b-tab
            v-for="(item, index) in tabsSchema"
            :key="index"
            lazy
          >
            <template #title>
              <h4>{{ item.name }}</h4>
            </template>
            <b-row v-if="item.children">
              <b-col
                v-for="(child, childIndex) in item.children"
                :key="childIndex"
                :lg="child.fullSize ? 12 : 6"
                md="12"
                sm="12"
                class="mb-1"
              >
                <component
                  :is="child.component"
                  class="mb-0"
                  v-bind="getBindValues(child.propName)"
                  @refresh="refresh"
                />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </vx-card>
    </b-col>
  </b-row>
</template>

<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { BTabs, BTab, BRow, BCol } from 'bootstrap-vue'
import { tabsSchema } from '@/views/players/playerSchema'
import MainInfo from '@/views/players/components/profile/ProfileMainInfo'
import VxCard from '@/components/VxCard'
import router from '@/router'
import { isEmpty } from 'lodash'
import { passDataToResource, players, useResource } from '@/services/resources'
import { playerGetOneIncludes } from '@/services/resources/domains/players'
import { usePlayerAdapter } from '@/views/players/playerAdapter'

export default {
  name: 'Player',
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    VxCard,
    MainInfo
  },
  props: {
    dataResolverResponse: {
      type: Object,
      default: null
    }
  },
  setup ({ dataResolverResponse }) {
    const tabIndex = ref(0)

    const { playerModel, backToFrontMapper } = usePlayerAdapter()

    const setHashToRef = ({ tab }) => {
      tabIndex.value = Number(tab)
    }

    const setHashToRoute = (query) => {
      router.push({
        path: router.currentRoute.fullPath,
        query
      }).catch(() => {})
    }

    const getBindValues = (propName) => {
      if (!propName) return

      return {
        [propName]: playerModel[propName]
      }
    }

    const refresh = async () => {
      const resource = passDataToResource(players.getOne, {
        requestParams: {
          urlParams: { id: dataResolverResponse.id },
          params: { include: playerGetOneIncludes }
        }
      })

      const { can, callRequest } = useResource(resource)
      if (!can) return

      const [err, res] = await callRequest()
      if (err) return

      await backToFrontMapper(res.data)
    }

    onBeforeMount(() => {
      backToFrontMapper(dataResolverResponse)

      if (isEmpty(router.currentRoute.query)) {
        setHashToRoute({ tab: tabIndex.value })
        return
      }

      setHashToRef(router.currentRoute.query)
    })

    watch(() => tabIndex.value, () => {
      setHashToRoute({ tab: tabIndex.value })
    })

    return {
      tabsSchema,
      tabIndex,

      playerModel,
      getBindValues,
      refresh
    }
  }
}
</script>
