<template>
  <vx-table-simple
    :items="items"
    :columns="columns"
    stacked
  >
    <template #title>
      Drops Other Info
    </template>
  </vx-table-simple>
</template>

<script>
import { VxTableSimple, VxCellMoney } from '@/components/table'

export default {
  name: 'DropsAdditionalInfo',
  components: {
    VxTableSimple,
    VxCellMoney
  },
  props: {
    dropsAdditionalInfo: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const items = [props.dropsAdditionalInfo]

    const columns = [
      {
        key: 'usual_chest_opening_count'
      },
      {
        key: 'usual_chest_spent_sum',
        component: VxCellMoney
      },
      {
        key: 'usual_chest_won_item_sum',
        component: VxCellMoney
      },
      {
        key: 'upgrade_bet_sum',
        component: VxCellMoney
      },
      {
        key: 'upgrade_won_sum',
        component: VxCellMoney
      },
      { key: 'exchange_count' }
    ]

    return {
      items,
      columns
    }
  }
}
</script>
