<template>
  <div>
    <vx-table-simple
      :items="items"
      :columns="columns"
      stacked
    >
      <template #title>
        General Info
      </template>

      <template #actions>
        <vx-button
          size="sm"
          variant="primary"
          :can="statusButton.can"
          :icon="statusButton.icon"
          @click="openModal(item)"
        >
          Change status
        </vx-button>
      </template>
    </vx-table-simple>
    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <profile-player-status-editor :entity="entity" @submit="handleSubmit" />
    </b-modal>`
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import {
  VxCellBadges,
  VxCellButtons,
  VxCellDate,
  VxCellLink,
  VxCellMoney,
  VxTableSimple
} from '@/components/table'
import { players, playerStatuses, useResource } from '@/services/resources'
import { VxButton } from '@/components/buttons'
import ProfilePlayerStatusEditor from '@/views/players/components/profile/ProfilePlayerStatusEditor'
import { useModalEditor } from '@/services/modal'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { buildRoute, path } from '@/router'

export default {
  name: 'ProfileGeneralInfo',
  components: {
    VxTableSimple,
    VxCellDate,
    VxCellMoney,
    VxCellButtons,
    VxCellLink,
    VxCellBadges,
    VxButton,
    ProfilePlayerStatusEditor,
    BModal
  },
  props: {
    profileGeneral: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const items = computed(() => [props.profileGeneral])
    const item = computed(() => items.value[0] || null)

    const { can: canUpdateStatus } = useResource(players.changeStatus)

    const { modalIsOpen, entity, openModal, closeModal } = useModalEditor()
    const modalTitle = 'Edit status'

    const handleSubmit = () => {
      closeModal()
      emit('refresh')
    }

    const statusButton = {
      ...buttons.edit,
      can: canUpdateStatus
    }

    const columns = [
      {
        key: 'xp',
        label: 'XP'
      },
      {
        key: 'created_at',
        label: 'Registered at',
        component: VxCellDate
      },
      {
        key: 'updated_at',
        label: 'Last active',
        component: VxCellDate
      },
      {
        key: 'tracking.ga_cid',
        label: 'GA_CID'
      },
      {
        key: 'tracking.ip',
        label: 'Last IP'
      },
      {
        key: 'profile.status',
        label: 'Current status',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [playerStatuses.active]: 'success',
            [playerStatuses.frozen]: 'warning',
            [playerStatuses.blocked]: 'danger'
          }
        }
      },
      {
        key: 'player_welcome_offer',
        component: VxCellLink,
        tdAttr: (value) => {
          if (!value) return

          const { status, welcome_offer_id } = value
          return {
            label: status,
            subLabel: `#${welcome_offer_id}`,
            href: buildRoute(path.welcomeOffers, { query: { id: welcome_offer_id } })
          }
        }
      }
    ]

    return {
      item,
      items,
      columns,

      statusButton,

      modalTitle,
      modalIsOpen,
      entity,
      handleSubmit,
      openModal,
      closeModal
    }
  }
}
</script>
