<template>
  <div>
    <vx-table-simple
      :items="items"
      :columns="columns"
      :loading="loading"
    >
      <template #title>
        Payment accounts info
      </template>
    </vx-table-simple>
    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <balance-change-request-editor
        :entity="entity"
        :player-id="playerId"
        :currency-request="currencyRequest"
        @submit="handleSubmit"
      />
    </b-modal>
  </div>
</template>

<script>
import { VxCellBadges, VxCellButtons, VxCellMoney, VxTableSimple } from '@/components/table'
import { balanceChanges, passDataToResource, players, useResource } from '@/services/resources'
import { getRouteIdParam } from '@/router'
import { onBeforeMount, ref } from '@vue/composition-api'
import { useToast } from '@/plugins/toastification'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { useModalEditor } from '@/services/modal'
import { BModal } from 'bootstrap-vue'
import BalanceChangeRequestEditor from '@/views/balance-changes/BalanceChangeRequestEditor'

export default {
  name: 'FinancesPaymentAccountsInfo',
  components: {
    VxTableSimple,
    VxCellMoney,
    VxCellButtons,
    BalanceChangeRequestEditor,
    BModal
  },
  setup (props, { emit }) {
    const toast = useToast()
    const items = ref([])
    const currencyRequest = ref(null)
    const playerId = Number(getRouteIdParam())

    const { can: canCreateChangeRequest } = useResource(balanceChanges.createRequest)
    const resource = passDataToResource(players.accounts, {
      requestParams: {
        params: {
          include: 'currency',
          filter: { player_id: getRouteIdParam() }
        }
      }
    })
    const { can: canGetAccounts, loading, callRequest } = useResource(resource)

    const { modalIsOpen, entity, openModal, closeModal } = useModalEditor()

    const modalTitle = 'Create request'

    const handleSubmit = () => {
      closeModal()
      emit('refresh')
    }

    const getPaymentAccounts = async () => {
      if (!canGetAccounts) return

      const [err, res] = await callRequest()
      if (err) toast.error(err)

      items.value.push(...res.data)
    }

    const actionButtons = (currency) => {
      const makeChangeRequest = {
        ...buttons.edit,
        label: 'Create request',
        can: canCreateChangeRequest,
        onClick: () => {
          currencyRequest.value = currency
          openModal(null)
        }
      }

      return [makeChangeRequest]
    }

    onBeforeMount(async () => {
      await getPaymentAccounts()
    })

    const columns = [
      {
        key: 'currency_code',
        label: 'Currency'
      },
      {
        key: 'balance',
        component: VxCellMoney
      },
      {
        key: 'is_active',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            Active: 'success',
            Inactive: 'dark'
          }
        },
        formatter: (value) => {
          return value ? 'Active' : 'Inactive'
        }
      },
      {
        key: 'currency.code',
        label: 'Actions',
        component: VxCellButtons,
        tdAttr: (value) => ({
          buttons: actionButtons(value)
        })
      }
    ]

    return {
      loading,
      items,
      columns,

      playerId,
      currencyRequest,

      entity,
      handleSubmit,

      modalTitle,
      modalIsOpen,
      openModal,
      closeModal
    }
  }
}
</script>
