<template>
  <vx-table-simple
    :items="items"
    :columns="columns"
    stacked
  >
    <template #title>
      Steam Info
    </template>

    <template #actions>
      <vx-button
        size="sm"
        :loading="loading"
        :can="can"
        variant="primary"
        @click="refreshSteamData"
      >
        Refresh steam data
      </vx-button>
    </template>
  </vx-table-simple>
</template>

<script>
import { VxTableSimple, VxCellBadges, VxCellDate, VxCellLink } from '@/components/table'
import { VxButton } from '@/components/buttons'
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import { useToast } from '@/plugins/toastification'
import { passDataToResource, players, useResource } from '@/services/resources'
import { getRouteIdParam } from '@/router'

export default {
  name: 'ProfileSteamData',
  components: {
    VxTableSimple,
    VxCellLink,
    VxCellBadges,
    VxCellDate,
    VxButton
  },
  props: {
    profileSteam: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const toast = useToast()
    const items = ref([props.profileSteam])

    const refreshSteamResource = passDataToResource(players.refreshSteamData, {
      requestParams: {
        urlParams: { id: getRouteIdParam() }
      }
    })

    const { can, loading, callRequest } = useResource(refreshSteamResource)

    const refreshSteamData = async () => {
      if (!can) return

      const [err] = await callRequest()
      if (err) toast.error(err)

      toast.success('The job for refreshing Steam data has started. Please wait about five minutes for result')
    }

    const columns = [
      {
        key: 'steam_id',
        component: VxCellLink,
        tdAttr: (id, key, item) => ({
          label: `#${id}`,
          href: item.steam_url
        })
      },
      {
        key: 'trade_url',
        component: VxCellLink,
        tdAttr: (link) => ({
          label: `#${link}`,
          href: link
        })
      },
      {
        key: 'steam_profile.level',
        label: 'Steam level',
        component: VxCellBadges
      },
      {
        key: 'steam_profile.csgo_hours',
        label: 'CSGO: hours'
      },
      {
        key: 'steam_profile.updated_at',
        label: 'Steam info updated',
        component: VxCellDate
      }
    ]

    return {
      columns,
      items,
      loading,
      can,
      refreshSteamData
    }
  }
}
</script>
