<template>
  <vx-card>
    <div class="d-flex flex-sm-row flex-md-row flex-lg-column">
      <b-img
        :src="getAvatar"
        :class="{ 'mb-1': getAvatar }"
        fluid
        rounded
      />
      <b-col>
        <b-row class="pl-1">
          <span class="pr-0-5">Name:</span>
          <span class="font-weight-bolder mw-fit-content text-break">{{ name }}</span>
        </b-row>
        <b-row class="mt-1 pl-1">
          <span class="pr-0-5">ID:</span>
          <span class="font-weight-bolder">{{ id }}</span>
        </b-row>
        <b-row class="mt-1 pl-1">
          <span class="pr-0-5">CSBRO Profile:</span>
          <span class="font-weight-bolder">
            <b-link :href="profileLink" target="_blank">Link</b-link>
          </span>
        </b-row>
        <b-row class="mt-1 pl-1">
          <span class="pr-0-5">Email:</span>
          <span
            v-if="email"
            class="font-weight-bolder mw-fit-content text-break"
          >
            {{ email }}
          </span>
          <vx-no-info-dash v-else />
        </b-row>
      </b-col>
    </div>
  </vx-card>
</template>

<script>
import { computed } from '@vue/composition-api'
import { BRow, BCol, BImg, BLink } from 'bootstrap-vue'
import { steamAvatarSize, steamAvatarExtension } from '@/services/resources'
import VxCard from '@/components/VxCard'
import VxNoInfoDash from '@/components/VxNoInfoDash'

export default {
  name: 'ProfileMainInfo',
  components: { VxNoInfoDash, VxCard, BRow, BCol, BImg, BLink },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props) {
    const { data: { id, name, email, avatar } = {} } = props

    const profileLink = `${process.env.VUE_APP_CSBRO}/players/${id}`

    const getAvatar = computed(() => {
      if (!avatar) return null

      return avatar.replace(steamAvatarExtension.jpg, `${steamAvatarSize.full}${steamAvatarExtension.jpg}`)
    })

    return {
      getAvatar,
      id,
      name,
      profileLink,
      email
    }
  }
}
</script>
<style scoped>
.pr-0-5 {
  padding-right: 0.5rem;
}
.mw-fit-content {
  max-width: fit-content;
}
</style>
