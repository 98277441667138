<template>
  <vx-card no-body>
    <template #title>
      Tags
    </template>

    <b-card-body class="border-top pt-1">
      <vx-form
        v-slot="{ can, loading }"
        :resource="formResource"
        @submit="handleSubmit"
      >
        <vx-select-resource
          v-model="selectedTags"
          :resource="tagsResource"
          :reduce="({ id }) => id"
          options-label="name"
          label="Tags"
          name="tags"
          multiple
          deselect-from-dropdown
        />

        <vx-button
          :loading="loading"
          :can="can"
          variant="primary"
          type="submit"
        >
          Save
        </vx-button>
      </vx-form>
    </b-card-body>
  </vx-card>
</template>

<script>
import { VxForm, VxSelectResource } from '@/components/form'
import VxCard from '@/components/VxCard'
import { VxButton } from '@/components/buttons'
import { passDataToResource, players, tags } from '@/services/resources'
import { ref, onBeforeMount } from '@vue/composition-api'
import { getRouteIdParam } from '@/router'
import { BCardBody } from 'bootstrap-vue'

export default {
  name: 'ProfileTags',
  components: {
    VxCard,
    VxForm,
    VxSelectResource,
    VxButton,
    BCardBody
  },
  props: {
    profileTags: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const tagsResource = tags.getAll
    const selectedTags = ref([])

    const formResource = passDataToResource(players.updateTags, {
      data: { tags: selectedTags.value },
      requestParams: { urlParams: { id: getRouteIdParam() } }
    })

    const handleSubmit = ([err]) => {
      if (err) return

      emit('refresh')
    }

    onBeforeMount(() => {
      selectedTags.value = props.profileTags?.tags?.map(({ id }) => id)
    })

    return {
      formResource,
      tagsResource,
      selectedTags,
      handleSubmit
    }
  }
}
</script>
