<template>
  <vx-table-simple
    :items="items"
    :columns="columns"
    stacked
  >
    <template #title>
      Active payment account
    </template>
  </vx-table-simple>
</template>

<script>
import { VxTableSimple, VxCellMoney } from '@/components/table'
import { computed } from '@vue/composition-api'

export default {
  name: 'FinancesActivePaymentAccount',
  components: {
    VxTableSimple,
    VxCellMoney
  },
  props: {
    financesActivePaymentAccount: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const items = computed(() => [props.financesActivePaymentAccount])

    const columns = [
      {
        key: 'payment_account.balance',
        label: 'Current balance',
        component: VxCellMoney
      },
      {
        key: 'brc_account.balance',
        label: 'Current BRC balance',
        component: VxCellMoney
      },
      {
        key: 'total_payment_sum',
        label: 'Total payments',
        component: VxCellMoney
      },
      {
        key: 'total_drops_withdrawn_sum',
        label: 'Total drops withdrawn',
        component: VxCellMoney
      }
    ]

    return {
      items,
      columns
    }
  }
}
</script>
