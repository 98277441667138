var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{attrs:{"no-body":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Tags ")]},proxy:true}])},[_c('b-card-body',{staticClass:"border-top pt-1"},[_c('vx-form',{attrs:{"resource":_vm.formResource},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var can = ref.can;
var loading = ref.loading;
return [_c('vx-select-resource',{attrs:{"resource":_vm.tagsResource,"reduce":function (ref) {
	var id = ref.id;

	return id;
},"options-label":"name","label":"Tags","name":"tags","multiple":"","deselect-from-dropdown":""},model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}}),_c('vx-button',{attrs:{"loading":loading,"can":can,"variant":"primary","type":"submit"}},[_vm._v(" Save ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }