<template>
  <vx-table-simple
    :items="items"
    :columns="columns"
    stacked
  >
    <template #title>
      Antifraud Points
    </template>
  </vx-table-simple>
</template>

<script>
import {
  VxCellButtons,
  VxTableSimple,
  VxCellAntifraudPoints
} from '@/components/table'

export default {
  name: 'AntifraudActivePoints',
  components: {
    VxCellButtons,
    VxTableSimple,
    VxCellAntifraudPoints
  },
  props: {
    antifraudActivePoints: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const items = [props.antifraudActivePoints]

    const columns = [
      {
        key: 'active_antifraud_activation.point_log',
        label: 'Points log',
        tdClass: ['d-flex', 'align-items-center'],
        component: VxCellAntifraudPoints
      },
      {
        key: 'active_antifraud_activation.total_points',
        label: 'Total points'
      }
    ]

    return {
      columns,
      items
    }
  }
}
</script>
