<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-select
      v-model="formValue.status"
      :rules="rules.status"
      :options="options"
      :clearable="false"
      name="status"
      label="Status"
    />

    <vx-textarea
      v-model="formValue.reason"
      :rules="rules.reason"
      name="reason"
      label="Reason"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { reactive } from '@vue/composition-api'
import { setValuesToForm } from '@/services/form'
import { VxForm, VxTextarea, VxSelect } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { passDataToResource, players, playerStatuses } from '@/services/resources'
import { getRouteIdParam } from '@/router'

export default {
  name: 'ProfilePlayerStatusEditor',
  components: {
    VxTextarea,
    VxForm,
    VxButton,
    VxSelect
  },
  props: {
    entity: {
      type: Object,
      default: null
    }
  },
  setup ({ entity }, { emit }) {
    const backToFrontMapper = ({ profile }) => {
      return {
        status: profile.status
      }
    }

    const formValue = reactive({
      status: '',
      reason: ''
    })

    setValuesToForm(backToFrontMapper(entity), formValue)

    const formResource = passDataToResource(players.changeStatus, {
      requestParams: {
        urlParams: { id: getRouteIdParam() }
      }
    })

    const rules = {
      status: {
        required: true
      },
      reason: {
        max: 2000
      }
    }

    const options = Object.keys(playerStatuses)

    const handleSubmit = ([, res]) => {
      if (res) emit('submit', res)
    }

    return {
      formResource,
      formValue,
      rules,
      options,
      handleSubmit
    }
  }
}
</script>
