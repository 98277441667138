<template>
  <vx-table-simple
    :items="items"
    :columns="columns"
    stacked
  >
    <template #title>
      Drops Info
    </template>
  </vx-table-simple>
</template>

<script>
import {
  VxCellMoney,
  VxTableSimple
} from '@/components/table'
import { moneyFormat } from '@/services/utils'

export default {
  name: 'DropsMainInfo',
  components: {
    VxTableSimple,
    VxCellMoney
  },
  props: {
    dropsMainInfo: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const items = [props.dropsMainInfo]

    const columns = [
      { key: 'drop_count' },
      { key: 'active_drop_count' },
      {
        key: 'withdrawal_drop_limit.total_drop_price',
        label: 'Total Drop Price',
        component: VxCellMoney
      },
      {
        key: 'active_drop_sum',
        label: 'Active Drop Price',
        component: VxCellMoney
      },
      { key: 'chest_drop_count' },
      { key: 'exchange_drop_count' },
      {
        key: 'player_totals',
        label: 'Player Total Drops',
        formatter: (value) => {
          if (!value) return null

          const [drops] = value.filter(item => item.name === 'drops')
          return moneyFormat(drops.value)
        }
      },
      {
        key: 'sellable_drop_sum',
        component: VxCellMoney
      },
      {
        key: 'upgradable_drop_sum',
        label: 'Upgradable Drop Sum',
        component: VxCellMoney
      },
      { key: 'upgrade_drop_count' },
      {
        key: 'withdrawing_drop_sum',
        label: 'Withdrawing Drop Sum',
        component: VxCellMoney
      },
      {
        key: 'total_withdrawal_sum',
        label: 'Total drops purchased',
        component: VxCellMoney
      }
    ]

    return {
      items,
      columns
    }
  }
}
</script>
