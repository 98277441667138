<template>
  <vx-table-simple
    ref="tableRef"
    :items="items"
    :columns="columns"
    stacked
  >
    <template #title>
      Provably Fair Info
    </template>
  </vx-table-simple>
</template>

<script>
import { ref } from '@vue/composition-api'
import { VxCellButtons, VxTableSimple } from '@/components/table'
import { VxButton } from '@/components/buttons'
import { provablyFair, useResource } from '@/services/resources'
import { buildRoute, getRouteIdParam, path } from '@/router'

export default {
  name: 'ProfileProvablyFair',
  components: { VxTableSimple, VxButton, VxCellButtons },
  props: {
    profileProvablyFair: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { can: canClientProvablyFair } = useResource(provablyFair.clientSeeds)
    const { can: canServerProvablyFair } = useResource(provablyFair.serverSeeds)
    const clientProvablyFair = buildRoute(path.clientSeeds, { query: { id: getRouteIdParam() } })
    const serverProvablyFair = buildRoute(path.serverSeeds, { query: { player_id: getRouteIdParam() } })

    const actionButtons = () => {
      const variant = 'primary'

      const clientView = {
        label: 'Client seed',
        variant,
        can: canClientProvablyFair,
        to: clientProvablyFair
      }

      const serverView = {
        label: 'Server seed',
        variant,
        can: canServerProvablyFair,
        to: serverProvablyFair
      }

      return [clientView, serverView]
    }

    const items = ref([props.profileProvablyFair])
    const columns = [
      {
        key: 'latest_client_seed.seed',
        label: 'Client seed'
      },
      {
        key: 'latest_server_seed.public_hash',
        label: 'Public hash',
        tdClass: ['d-flex', 'align-items-center']
      },
      {
        key: 'actions',
        label: 'History',
        tdClass: ['d-flex', 'align-items-center'],
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      }
    ]

    return {
      canClientProvablyFair,
      canServerProvablyFair,
      clientProvablyFair,
      serverProvablyFair,

      items,
      columns
    }
  }
}
</script>
