<template>
  <vx-table-simple
    :items="items"
    :columns="columns"
    stacked
  >
    <template #title>
      Account bonuses
    </template>
  </vx-table-simple>
</template>

<script>
import { VxTableSimple } from '@/components/table'
import { computed } from '@vue/composition-api'

export default {
  name: 'FinancesAccountBonuses',
  components: {
    VxTableSimple
  },
  props: {
    financesAccountBonuses: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const items = computed(() => [props.financesAccountBonuses])

    const columns = [
      { key: 'active_bonus_count' },
      { key: 'disabled_bonus_count' },
      { key: 'expired_bonus_count' },
      { key: 'used_bonus_count' },
      { key: 'total_bonus_count' },
      { key: 'used_promocode_count' }
    ]

    return {
      items,
      columns
    }
  }
}
</script>
